<template>
	<div class="about">
		<v-container class="pa-0" fluid>
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="8" xl="8" align="center" style="margin-top: 100px">


					<v-row align="center" justify="center" class="btn-reserve">
						<v-col sm="6" md="5" lg="4" xl="4">
							<v-btn
									color="#e60044"
									dark
									depressed
									tile
									block

							>
								LINE 端末を登録
							</v-btn>
						</v-col>
					</v-row>


			</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
	}
</script>
<style scoped>

</style>
